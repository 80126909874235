/* Font Faces */

/* Shabnam Font Family */
@font-face {
  font-family: 'Shabnam';
  src: url('assets/fonts/shabnam/Shabnam-Light-FD.woff') format('woff'),
    url('assets/fonts/shabnam/Shabnam-Light-FD.eot') format('eot'),
    url('assets/fonts/shabnam/Shabnam-Light-FD.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Shabnam';
  src: url('assets/fonts/shabnam/Shabnam-FD.woff') format('woff'),
    url('assets/fonts/shabnam/Shabnam-FD.eot') format('eot'),
    url('assets/fonts/shabnam/Shabnam-FD.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Shabnam';
  src: url('assets/fonts/shabnam/Shabnam-Bold-FD.woff') format('woff'),
    url('assets/fonts/shabnam/Shabnam-Bold-FD.eot') format('eot'),
    url('assets/fonts/shabnam/Shabnam-Bold-FD.ttf') format('truetype');
  font-weight: 600;
}
/* End Shabnam Font Family */

/* Poppins Font Family */
@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  unicode-range: U+0020-007F;
}
/* End Poppins Font Family */

html,
body,
#root {
  width: 100%;
  height: 100%;
}
